<template>
  <div class="setting-configuration">

    <b-row>
      <b-col
        md="6"
        xl="6"
        class="mb-1"
      >
        <b-card
          no-body
          class="setting-configuration-card"
        >
          <b-row>
            <b-col
              md="5"
              xl="5"
              class="text-center line-height-38"
            >
              Daily SMS Code Limit
            </b-col>
            <b-col
              md="6"
              xl="6"
              class=""
            >
              <!-- basic -->
              <b-form-group>
                <b-form-input
                  v-model="smsLimitDaily"
                  placeholder="Daily SMS Code Limit"
                  type="number"
                  :disabled="isEdit"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              md="5"
              xl="5"
              class="text-center line-height-38"
            >
              SMS Code Expiry Limit (minutes)
            </b-col>
            <b-col
              md="6"
              xl="6"
              class=""
            >
              <!-- basic -->
              <b-form-group>
                <b-form-input
                  v-model="smsLimitExp"
                  placeholder="SMS Code Expiry Limit (minutes)"
                  type="number"
                  :disabled="isEdit"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              md="5"
              xl="5"
              class="text-center line-height-38"
            >
              Daily Deposit Limit
            </b-col>
            <b-col
              md="6"
              xl="6"
              class=""
            >
              <!-- basic -->
              <b-form-group>
                <b-form-input
                  v-model="depositLimitDaily"
                  placeholder="Daily Deposit Limit"
                  type="number"
                  :disabled="isEdit"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              md="5"
              xl="5"
              class="text-center line-height-38"
            >
              Daily Withdrawal Limit
            </b-col>
            <b-col
              md="6"
              xl="6"
              class=""
            >
              <!-- basic -->
              <b-form-group>
                <b-form-input
                  v-model="withdrawLimitDaily"
                  placeholder="Daily Withdrawal Limit"
                  type="number"
                  :disabled="isEdit"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-hasBtn="`configurations`">
            <b-col
              md="12"
              xl="12"
              class="text-center"
            >
              <b-button
                variant="primary"
                class="mt-2"
                @click="saveConfig"
              >{{ isEdit?'Edit':'Save' }}</b-button>
            </b-col>
          </b-row>

        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  ref, computed, onUnmounted, getCurrentInstance, onMounted,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import {
  // BForm,
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  VBModal,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import { showToast } from '@/libs/utils'
import store from '@/store'
import settingsStoreModule from './settingsStoreModule'

const { mapState: mapStateWallet } = createNamespacedHelpers('wallet')
export default {
  components: {
    // BForm,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
  },
  setup() {
    const { proxy } = getCurrentInstance()
    // 注册vuex
    const SettingsStoreModuleName = 'settings'
    // Register module
    if (!store.hasModule(SettingsStoreModuleName)) { store.registerModule(SettingsStoreModuleName, settingsStoreModule) }
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SettingsStoreModuleName)) { store.unregisterModule(SettingsStoreModuleName) }
    })

    const isEdit = ref(true)

    const depositLimitDaily = ref('')
    const smsLimitDaily = ref('')
    const smsLimitExp = ref('')
    const withdrawLimitDaily = ref('')
    const resetData = () => {
      depositLimitDaily.value = ''
      smsLimitDaily.value = ''
      smsLimitExp.value = ''
      withdrawLimitDaily.value = ''
    }
    const getConfigData = () => {
      proxy.$showLoading()
      const res = store.dispatch('settings/configLoad')
      res
        .then(response => {
          proxy.$hideLoading()
          if (response.data.code == 200) {
            depositLimitDaily.value = response.data.data.deposit_limit_daily
            smsLimitDaily.value = response.data.data.sms_limit_daily
            smsLimitExp.value = response.data.data.sms_limit_exp
            withdrawLimitDaily.value = response.data.data.withdraw_limit_daily
          } else {
            resetData()
          }
        })
        .catch(error => {
          proxy.$hideLoading()
        })
    }
    const saveConfig = () => {
      if (isEdit.value) {
        isEdit.value = false
        return
      }
      proxy.$showLoading()
      const params = {
        data: {
          sms_limit_daily: smsLimitDaily.value,
          sms_limit_exp: smsLimitExp.value,
          deposit_limit_daily: depositLimitDaily.value,
          withdraw_limit_daily: withdrawLimitDaily.value,
        },
      }
      const res = store.dispatch('settings/configSave', params)
      res
        .then(response => {
          proxy.$hideLoading()
          if (response.data.code == 200) {
            isEdit.value = true
            getConfigData()
            showToast(proxy, 'success', 'success')
          } else {
            resetData()
            showToast(proxy, 'warning', response.data.message)
          }
        })
        .catch(error => {
          proxy.$hideLoading()
          showToast(proxy, 'warning', error.message)
        })
    }
    onMounted(() => {
      getConfigData()
    })

    return {
      isEdit,
      depositLimitDaily,
      smsLimitDaily,
      smsLimitExp,
      withdrawLimitDaily,
      // func
      saveConfig,
      showToast,
    }
  },
}
</script>
<style lang="scss" scoped>
.setting-configuration {
  .line-height-38 {
    line-height: 38px;
  }
  .setting-configuration-card {
    padding: 40px 0;
  }
}
</style>
