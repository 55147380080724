import { render, staticRenderFns } from "./Configurations.vue?vue&type=template&id=3cc46b75&scoped=true&"
import script from "./Configurations.vue?vue&type=script&lang=js&"
export * from "./Configurations.vue?vue&type=script&lang=js&"
import style0 from "./Configurations.vue?vue&type=style&index=0&id=3cc46b75&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cc46b75",
  null
  
)

export default component.exports